:root {
  --pink: #ec5d7f;
  --lightPink: #FFC6D3;
  --medPink: #FFB2C5;
}

#brandHeader,
#homeContainer {
  animation: fadeIn 1s;
}

/* week Tracker styling */

#weekTracker {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 3px;
}

.weekday-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-fade {
  color: #000;
  text-align: center;
  font-family: "Helvetica Neue LT Std", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  opacity: 0.2;
}

.color-box {
  width: 48.391px;
  height: 48.391px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid #ec5d7f;
}

.back-light {
  background-color: var(--lightPink);
}

.back-med {
  background-color: var(--medPink);
}

.back-dark {
  background-color: var(--pink);
}

.today {
  
  transition: all 1s;
  transform-origin: top;
  transform: scaleY(1.55);

}

/* calorie counter */

#sweatCalories {
  text-align: center;
  margin-top: 11.35px;
}

#calHeader {
  font-size: 65px;
  margin-top: 0;
  margin-bottom: 0px;
}

#metricsHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#countForm {
  margin-top: 400px;
}

#sweatCount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: -100px
}

#minColumnText {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  margin-left: 1.5em;
}

#sweatColumnText {
  padding-top: 8em;
}

.formField {
  border: 1px solid transparent;
  border-bottom: 1px solid var(--pink);
  margin-bottom: 30px;
  margin-top: 30px;
}

.text-bubble {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ec5d7f;
  font-family: "Helvetica Neue LT Std", sans-serif;
  font-size: 100px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-bold {
  color: #ec5d7f;
  font-family: "Helvetica Neue LT Std";
  font-size: 100px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
}

.text-pink {
  color: #ec5d7f;
  font-family: "Helvetica Neue LT Std", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  margin: 0px;
}

#sweatnText {
  text-align: end;
  padding-left: 40px;
}

#sweatTextRight {
  text-align: center;
  padding-left: 50px;
}

#sweatTextLeft {
  padding-right: 50px;
}

#doHeader {
  margin-bottom: -40px;
  margin-top: 0;
}

#sweatHeader {
  display: flex;
  text-align: center;
  flex-direction: column;
  margin-bottom: 46px;
}



#kcalSpan {
  font-size: 35px;
}

#timerText {
  text-align: center
}

#repCounter {
  margin: 0;
  margin-top: -36px;
}
.submitBtn {
  background: var(--pink);
  color: white;
  font-family: "Helvetica Neue LT Std", sans-serif;
  font-style: normal;
  font-weight: 750;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 2vh;
  border-radius: 8px;
  height: 54px;
}

#sweatnBtn {
  margin-top: -7em;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  margin-left: 0;
  animation: fadeIn 1s;
}



.short-margin {
  margin-top: 53.64px;
}

.long-margin {
  margin-top: 381px;
}

.loadSection {
  width: 50vw;
  margin: auto;
  margin-top: 40vh;
  animation: fadeIn 1s;
}

#loaderContainer {
  display: flex;
  justify-content: center;
}

.hidden {
  display: none;
}

.exercise-list {
  color: var(--pink);
  font-family: "Helvetica Neue LT Std", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  animation: fadeIn 1s;
  margin-bottom: -2em;
  min-height: 287px;
}

.list-item {
  margin-bottom: 15px;
  animation: fadeIn 1s;
}
.timeSelect {
  border-radius: 8px;
  border: 1px solid #ec5d7f;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: transparent;
  display: flex;
  margin-bottom: 1em;
  width: 294px;
  height: 54px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #ec5d7f;
  text-align: center;
  font-family: "Helvetica Neue LT Std", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  align-self: center;
}

#repsForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#repsFormRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#minusSvg {
  padding-top: 20px;
}

#repsHeader,
#loadHeader {
  font-family: "Helvetica Neue LT Std", sans-serif;
  text-align: left;
  color: var(--pink);
  font-size: 22px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  margin-left: 5px;
}

#loadHeader {
  text-align: center;
}

#minHeader {
  font-family: "Helvetica Neue LT Std", sans-serif;
  text-align: center;
  color: var(--pink);
  font-size: 14px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  margin-bottom: 22px;
}

/* Loading bar style */

.progress {
  width: 200px;
  height: 20px;
  background: linear-gradient(#ec5d7f 0 0) left/0% 100% no-repeat #e0e2db;
  animation: progressLoader 2s infinite linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes progressLoader {
  100% {
    background-size: 100% 100%;
  }
}
