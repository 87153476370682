:root {
  --pink: #ec5d7f;
}

a {
  text-decoration: none;
}

#brandHeader {
  font-family: "Helvetica Neue LT Std", sans-serif;
  font-style: normal;
  font-weight: 750;
  font-size: 22px;
  line-height: 26px;
  text-align: start;
  margin-left: 5vw;
  color: var(--pink);
  margin-top: 57px;
  margin-left: 46px;
}

#brandBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#logOutBtn {
  background: var(--pink);
  text-decoration: none;
  color: white;
  font-family: "Helvetica Neue LT Std", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
  border-radius: 8px;
}
