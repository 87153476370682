:root {
    --pink: #ec5d7f;
  }

  #brandHeader, #signUpContainer {
    animation: fadeIn 1s
  }

  .signUpField {
    border: 1px solid transparent;
    border-bottom: 1px solid var(--pink);
    margin-bottom: 5vh;
  }

  #signUpBtn, #loginBtn, #loginBtn a {
    background: var(--pink);
    text-decoration: none;
    color: white;
    font-family: "Helvetica Neue LT Std", sans-serif;
    font-style: normal;
    font-weight: 750;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 2vh;
    border-radius: 8px;
    height: 54px;
  }

  #signUpForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  #signUpContainer {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin: auto;
    margin-top: 35vh;
  }

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}